<script setup lang="ts">
import { transformUrl } from "@/helpers/link-transformers";

const props = defineProps<{
	crumbs: readonly string[] | undefined;
}>();

const route = useRoute();

const breadcrumbs = computed(() => {
	return (
		props.crumbs?.map((crumb, index) => {
			const resolveBreadcrumbUrl = (crumb: string, index: number) => {
				if (crumb.toLowerCase() === "home") {
					return "/";
				}

				return route.path
					.split("/")
					.slice(0, index + 1)
					.join("/");
			};

			return {
				"@type": "ListItem",
				position: index,
				name: crumb,
				item: resolveBreadcrumbUrl(crumb, index)
			};
		}) ?? []
	);
});

useBreadcrumbsJsonLD(breadcrumbs.value);
</script>

<template>
	<div class="md:bg-orange rounded md:py-2 md:px-4 flex gap-4 self-start items-center">
		<template v-for="(crumb, index) in breadcrumbs" :key="`breadcrumb_${crumb}`">
			<NuxtLink :to="transformUrl(crumb.item)">
				<span
					class="text-xs"
					:class="{
						'opacity-50': index !== (breadcrumbs.length || 0) - 1
					}"
				>
					{{ crumb.name }}
				</span>
			</NuxtLink>
			<span class="opacity-50" v-if="index < (breadcrumbs.length || 0) - 1"> / </span>
		</template>
	</div>
</template>
